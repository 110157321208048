import React, { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'

import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { useQueryString } from 'frontend/hooks'

import Header from './Header'
import ProductTable from './ProductTable'
import InstructionStatus from './InstructionStatus'
import TrackingMethod from './TrackingMethod'
import PayerWebsiteLink from './PayerWebsiteLink'
import NavigationInstructions from './NavigationInstructions'

interface Props {
  policyState: any
  setPolicyState: Dispatch<SetStateAction<any>>
}

/*
TODOS
- Feature flag this section
- Save values in state
- Allow inline editing of search terms on product table
- Throw instruction status into state
- Ask design about "link type" input. Don't think it's real
*/

const NavigationDetails = ({
  policyState,
  setPolicyState,
}: Props) => {
  const { payer_policy_document_id } = useQueryString()
  
  const {
    data: [policyDocument],
    isLoading,
  }: any = usePayerPolicyDocument({
    queryInput: { id: payer_policy_document_id },
  })

  const instruction = policyDocument?.instruction
  let products = policyDocument?.instruction?.products

  return (
    <Box
      sx={{
        marginTop: '24px',
        marginLeft: '12px',
        marginBottom: '36px',
        padding: '24px 24px 12px',
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        width: '423px',
      }}
    >
      <Header />
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '16px 0px' }}
      >
        <InstructionStatus
          instruction={instruction}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <TrackingMethod
          instruction={instruction}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <PayerWebsiteLink
          instruction={instruction}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <NavigationInstructions
          instruction={instruction}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <ProductTable
          setPolicyState={setPolicyState}
          products={products}
          isLoading={isLoading}
        />
      </div>
    </Box>
  )
}

export default NavigationDetails
