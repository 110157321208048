export const getConfigFormattedFields = (row: any, exportConfig: any) => {
  const formattedFields: any = {}

  exportConfig.forEach(({ accessor, cellValueFormatter }: any) => {
    let value = row[accessor]

    if (cellValueFormatter) {
      value = cellValueFormatter(value)
    }
    if (Array.isArray(value)) {
      value = value.join(', ')
    }

    formattedFields[accessor] = value
  })

  return formattedFields
}
