import React from 'react'

const LeftArrow = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.796943 4.36752L5.45335 0.259349C5.84529 -0.0864497 6.48076 -0.0864497 6.87271 0.259349C7.26465 0.605147 7.26465 1.1658 6.87271 1.5116L2.91878 5L6.87271 8.4884C7.26465 8.8342 7.26465 9.39485 6.87271 9.74065C6.48076 10.0864 5.84529 10.0864 5.45335 9.74065L0.796943 5.63248C0.401019 5.28317 0.401019 4.71683 0.796943 4.36752Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default LeftArrow
