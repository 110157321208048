import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Header from 'frontend/components/Header'
import Dialog from './Dialog'
import Button from 'frontend/components/Button'

import Color from '../../utils/color'
import Spacing from '../../utils/spacing'
import { Icon } from '../Icon/Icon'

const ActionBar = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: `${Spacing.S5} ${Spacing.S7}`,
})

const defaultContentWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const defaultContentStyle = {
  height: 'auto',
  width: 448,
}

const HeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: Spacing.S4,
  marginBottom: Spacing.S3,
  marginTop: Spacing.S3,
})

const SingleActionDialog = ({
  header,
  contentHeight = '60vh',
  subheader,
  children,
  cancelHandler,
  submitHandler,
  submitText,
  submitColor,
  contentWrapperStyle,
  contentStyle,
  headerStyle,
  isSubmitDisabled,
  buttonRef,
  cancelButtonStyle,
  submitButtonStyle,
  iconLink,
  dialogStyle,
  backgroundColor = Color.GRAY_LIGHT,
}) => {
  const combinedContentWrapperStyle = {
    ...defaultContentWrapperStyle,
    ...contentWrapperStyle,
  }

  const combinedWrapperStyle = {
    ...defaultContentStyle,
    ...contentStyle,
  }
  return (
    <Dialog
      contentWrapperStyle={combinedContentWrapperStyle}
      contentStyle={combinedWrapperStyle}
      dialogStyle={dialogStyle}
    >
      <HeaderContainer>
        <Icon iconLink={iconLink} />
        <Header
          header={header}
          subheader={subheader}
          style={{
            padding: Spacing.S3,
          }}
          headerStyle={headerStyle}
        />
      </HeaderContainer>

      <div
        style={{
          background: backgroundColor,
          height: contentHeight,
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
      <ActionBar>
        <Button
          type="secondary"
          color={Color.GRAY_DARK}
          onClick={cancelHandler}
          buttonStyle={{ marginRight: Spacing.S5, ...cancelButtonStyle }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          color={submitColor}
          onClick={submitHandler}
          isDisabled={isSubmitDisabled}
          buttonRef={buttonRef}
          buttonStyle={submitButtonStyle}
        >
          {submitText}
        </Button>
      </ActionBar>
    </Dialog>
  )
}

SingleActionDialog.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  children: PropTypes.node.isRequired,
  submitHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  submitText: PropTypes.oneOfType(PropTypes.string, PropTypes.node),
  submitColor: PropTypes.string,
  contentWrapperStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  buttonRef: PropTypes.any,
  cancelButtonStyle: PropTypes.any,
  submitButtonStyle: PropTypes.any,
  iconLink: PropTypes.any,
  dialogStyle: PropTypes.object,
}

SingleActionDialog.defaultTypes = {
  submitText: 'Submit',
  subheader: null,
  submitColor: Color.GREEN,
  contentWrapperStyle: {},
  contentStyle: {},
  headerStyle: {},
  dialogStyle: {},
  isSubmitDisabled: false,
}

export default SingleActionDialog
