import React from 'react'
import _ from 'lodash'

import { DataTableContainer } from 'frontend/components'

import { Pagination } from './Pagination'
import { Header } from './Header'
import UtilityBar from './UtilityBar'
import Criteria from './Criteria'
import Policies from './Policies'

interface Props {
  rowCount: number
  pageCount: number
  currentPage: number
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Table = ({
  rowCount,
  pageCount,
  currentPage,
  activeTab,
  setActiveTab,
  setCurrentPage,
}: Props) => {
  return (
    <DataTableContainer
      style={{ width: 'calc(100vw - 104px)', minWidth: 600 }}
    >
      <Header />
      <UtilityBar
        rowCount={rowCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Criteria />
      <Policies />
      <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </DataTableContainer>
  )
}

export default Table
