import React from 'react'

const LABEL_STYLES = {
  color: '#0E2539',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
}

interface Props {
  children: any;
}

const FieldLabel = ({ children }: Props) => {
  return (
    <label style={LABEL_STYLES}>
      {children}
    </label>
  )
}

export default FieldLabel
