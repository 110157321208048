import { saveAs } from 'file-saver'
import format from 'date-fns/format'

import { SHEET_NAME } from '../exportExcelSheet'

export const downloadExcel = async (workbook) => {
  const date = format(new Date(), 'MM-yyyy')
  const buffer = await workbook.xlsx.writeBuffer(SHEET_NAME)
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, `${SHEET_NAME} - ${date}.xlsx`)
}
