import React from 'react'

import { ScrollableTextInput } from 'frontend/components'
import FieldLabel from '../FieldLabel'

interface Props {
  instruction: any
  policyState: any
  setPolicyState: any
}

const PayerWebsiteLink = ({ instruction, policyState, setPolicyState }: Props) => {
  const handleOnChange = (e: any) =>{
    setPolicyState((prevState: any) => ({
      ...prevState,
      payer_website_link: e.currentTarget.value,
    }))
  }
  const text = policyState?.payer_website_link ?? instruction?.payer_website_link

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px 0px' }}>
      <FieldLabel>Payer Website Link</FieldLabel>
      <ScrollableTextInput text={text} onChange={handleOnChange} />
    </div>
  )
}

export default PayerWebsiteLink
