import React from 'react'
import _ from 'lodash'

import ClientSelect from './ClientSelect'
import TeamsSelect from './TeamsSelect'
import PrimaryTeamSelect from './PrimaryTeamSelect'

const Form = () => {
  return (
    <div style={{ margin: 12, marginBottom: 24 }}>
      <ClientSelect />
      <TeamsSelect/>
      <PrimaryTeamSelect />
    </div>
  )
}

export default Form
