import React, { useContext } from 'react'
import styled from '@emotion/styled'

import { useEmberUser } from 'frontend/entity-hooks/ember'
import { ListPanel } from 'frontend/components'
import { UtilityBarContext } from 'frontend/ClientManagement/Home/PanelsWrapper/TeamsUsersWrapper/UsersPanel/Panel/Panel'
import { useQueryInput } from '../useQueryInput'
import ListItem from './ListItem'

const SEARCH_STRING_KEY = 'user'
const SEARCH_STRING_ACCESSOR = 'id'

const Wrapper = styled.div({
  padding: '12px 24px',
})

const UsersListPanel = () => {
  const queryInput: any = useQueryInput()
  const { data: usersData } = useEmberUser({
    queryInput: queryInput,
  })

  let utilityBarContext = useContext(UtilityBarContext)
  utilityBarContext.setData(usersData as any[])

  return (
    <Wrapper>
      <ListPanel
        data={usersData}
        searchStringKey={SEARCH_STRING_KEY}
        searchStringAccessor={SEARCH_STRING_ACCESSOR}
        ListItem={ListItem}
      />
    </Wrapper>
  )
}

export default UsersListPanel
