import { useFlags } from 'launchdarkly-react-client-sdk'

import React from 'react'
import NavigationDetails from './NavigationDetails'

const Container = (props: any) => {
  const FLAG = useFlags()

  if (!FLAG.shouldShowNavigationDetails) return null
    
  return <NavigationDetails {...props} />
}

export default Container
