import React from 'react'
import styled from '@emotion/styled'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  pageCount: number
  currentPage: number
  setCurrentPage: any
}

const Wrapper = styled.span({
  marginLeft: 4,
  color: '#9DAEC4',
})

const Text = styled.span({
  fontSize: 12,
})

export const RightButton = ({
  pageCount,
  currentPage,
  setCurrentPage,
}: Props) => {
  const disabled = currentPage === pageCount
  const buttonColor = disabled ? '#9DAEC4' : '#798BA3'

  return (
    <Wrapper style={{ color: buttonColor }}>
      <button
        onClick={() => {
          if (!disabled) setCurrentPage(currentPage + 1)
        }}
      >
        <Text>Next</Text>
        <CustomSvgIcon
          iconName="RightArrow"
          iconColor={buttonColor}
          styles={{ width: 10, height: 10, marginLeft: 0.5 }}
        />
      </button>
    </Wrapper>
  )
}
