import React, { useContext } from 'react'

import { Select } from 'frontend/components'
import { Label, SELECT_STYLE } from '../shared'
import { useModalClient } from '../hooks'
import { FormContext } from '../MoveModal'

const ClientSelect = () => {
  const { form, setForm } = useContext(FormContext)
  const { options, onChange } = useModalClient(setForm)

  return (
    <>
      <Label>Move users to</Label>
      <Select
        styles={SELECT_STYLE}
        value={form?.client}
        placeholder={'pick a new client'}
        options={options}
        onChange={onChange}
      />
    </>
  )
}

export default ClientSelect
