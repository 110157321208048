import React from 'react'
import styled from '@emotion/styled'

import { LeftButton } from './LeftButton'
import { RightButton } from './RightButton'
import { PageNumbers } from './PageNumbers'

interface Props {
  currentPage: any
  setCurrentPage: any
  pageCount?: number
}

const Wrapper = styled.div({
  padding: '20px 0 20px 30px',
  zIndex: 1,
  boxShadow: '0px -1px #e9effb',
})

export const Pagination = ({
         currentPage,
         setCurrentPage,
         pageCount = 0,
       }: Props) => {
         return (
           <Wrapper>
             <LeftButton
               currentPage={currentPage}
               setCurrentPage={setCurrentPage}
             />
             <PageNumbers
               pageCount={pageCount}
               currentPage={currentPage}
               setCurrentPage={setCurrentPage}
             />
             <RightButton
               pageCount={pageCount}
               currentPage={currentPage}
               setCurrentPage={setCurrentPage}
             />
           </Wrapper>
         )
       }
