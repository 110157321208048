import React from 'react'
import { components } from 'react-select'

import { SELECT_ALL } from './constants'

export const ValueContainer = ({ children, ...props }) => {
  const currentSelectedOptions = props.getValue()
  let content = children

  if (currentSelectedOptions.length) {
    const selectedOptionsCount = currentSelectedOptions.filter(
      ({ value }) => value !== SELECT_ALL
    ).length

    const selectedCountLabel = (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {`${selectedOptionsCount} selected`}
      </div>
    )

    const inputField = children[1]
    content = [selectedCountLabel, inputField]
  }

  return (
    <components.ValueContainer {...props}>{content}</components.ValueContainer>
  )
}
