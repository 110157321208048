import React from 'react'
import styled from '@emotion/styled'

interface Props {
  products: any[]
  iconMap: any
}

const Wrapper = styled.div({
  display: 'flex',
  fontFamily: 'Inter',
  fontSize: 11,
  fontWeight: 400,
  justifyContent: 'space-between',
  padding: 2,
  zIndex: 1000,
})

const Products = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 26,
})

const PolicyStatuses = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled.div({
  fontWeight: 600,
  marginBottom: 2,
})

const Content = styled.div({
  display: 'flex',
  marginTop: 6,
})

export const TooltipTable = ({ products = [], iconMap }: Props) => {
  return (
    <Wrapper>
      <Products>
        <Header>Products</Header>
        {products.map(({ brand_name }, i) => (
          <Content key={i}>{brand_name}</Content>
        ))}
      </Products>
      <PolicyStatuses>
        <Header>Policy Status</Header>
        {products.map(({ policy_status }, i) => (
          <Content key={i}>{iconMap[policy_status]}</Content>
        ))}
      </PolicyStatuses>
    </Wrapper>
  )
}
