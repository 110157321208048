import { EDIT_CELL_TYPES } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { CONDENSED_TEXT_INPUT } = EDIT_CELL_TYPES

const getColConfig = (updateFn) => [
  { Header: 'Product', accessor: 'product' },
  {
    Header: <SearchTermHeader />,
    accessor: 'search_term',
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
]

export default getColConfig

const SearchTermHeader = () => (
  <div>
    <div>Search Term</div>
    <div style={{ fontSize: 10, fontWeight: 350, color: '#808A96' }}>
      (Click cell to edit)
    </div>
  </div>
)
