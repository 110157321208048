import { gql } from '@apollo/client'

export const GET_PAYER_POLICY_DOCUMENTS_COUNT = gql`
  query GetPayerPolicyDocumentsCount($input: JSON) {
    payerPolicyDocumentsCount(input: $input) {
      documentCount
      productCount
    }
  }
`
