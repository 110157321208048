import React, { useContext } from 'react'

import { Select } from 'frontend/components'
import { Label, SELECT_STYLE } from '../shared'
import { FormContext } from '../MoveModal'

const PrimaryTeamSelect = () => {
  const { form, setForm } = useContext(FormContext)
  return (
    <>
      <Label>
        With primary team
      </Label>
      <Select
        styles={SELECT_STYLE}
        placeholder={'pick new primary team'}
        options={form?.teams || []}
        value={form?.primaryTeam}
        onChange={(option: any) =>
          setForm((prev: any) => ({ ...prev, primaryTeam: option }))
        }
      />
    </>
  )
}

export default PrimaryTeamSelect
