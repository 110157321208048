import React from 'react'

export const Description = () => {
  return (
    <p style={{ margin: '24px 50px' }}>
      The following users will be moved to the selected client and new client
      teams. They will lose all <strong>current access</strong> and gain{' '}
      <strong>new access</strong>.
    </p>
  )
}
