import React from 'react'
import { Box } from '@mui/material'

import { SectionLabel } from '../SectionLabel'

const POLICY_DETAILS_LABEL = 'Policy Details'

const Header = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '12px',
      }}
    >
      <SectionLabel label={POLICY_DETAILS_LABEL} />
      {/* <div
          style={{
            color: '#808A96',
            paddingTop: 4,
            fontStyle: 'italic',
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
          Automation last run TIME.
        </div> */}
    </Box>
  )
}

export default Header
