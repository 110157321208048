interface Params {
  pageCount: number
  currentPage: number
}

const ELLIPSES = '...'
const NUM_LEFT_PAGE_NUMBERS = 4
const NUM_RIGHT_PAGE_NUMBERS = 4

export const getPageNumbers = ({ pageCount, currentPage }: Params) => {
  const hasRightEllipses = pageCount > 5 && currentPage < pageCount - 2
  const hasLeftEllipses = pageCount > 5 && currentPage >= NUM_LEFT_PAGE_NUMBERS

  if (hasRightEllipses && hasLeftEllipses) {
    return [
      1,
      ELLIPSES,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      ELLIPSES,
      pageCount,
    ]
  }

  if (hasLeftEllipses) {
    return [
      1,
      ELLIPSES,
      ...getIndexedArr(
        NUM_RIGHT_PAGE_NUMBERS,
        pageCount - NUM_RIGHT_PAGE_NUMBERS + 1
      ),
    ]
  }

  if (hasRightEllipses) {
    return [...getIndexedArr(NUM_LEFT_PAGE_NUMBERS, 1), ELLIPSES, pageCount]
  }

  return getIndexedArr(pageCount, 1)
}

const getIndexedArr = (n: number, index: number) => {
  return Array.from(Array(n), (_, i) => i + index)
}
