import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import {
  PAGE_SIZE,
  usePayerPolicyDocument,
} from 'frontend/entity-hooks/usePayerPolicyDocument'

import Table from './Table'

const Container = () => {
  const hasPageBeenRendered = useRef(false)
  const {
    page,
    view,
    payer_policy_document_id,
    ...filterValues
  }: any = useQueryString()
  const [prevFilterValues, setPrevFilterValues] = useState(filterValues)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const [activeTab, setActiveTab] = useState(
    view ? view.toLowerCase() : 'policies'
  )

  const { count }: any = usePayerPolicyDocument()
  const { pageCount, rowCount } = getCounts(count, activeTab)
  useRerender(
    currentPage,
    activeTab,
    filterValues,
    hasPageBeenRendered,
    setCurrentPage
  )

  resetStateOnFilterChange(
    filterValues,
    prevFilterValues,
    setCurrentPage,
    setPrevFilterValues
  )

  return (
    <Table
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageCount={pageCount}
      rowCount={rowCount}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  )
}

export default Container

const getCounts = (count: any, activeTab: any) => {
  const documentCount = count?.documentCount
  const productCount = count?.productCount
  let rowCount = activeTab === 'policies' ? documentCount : productCount
  rowCount = rowCount ? rowCount : 0
  const pageCount = documentCount && Math.ceil(documentCount / PAGE_SIZE)
  return { pageCount, rowCount }
}

const useRerender = (
  currentPage: number,
  activeTab: any,
  filterValues: any,
  hasPageBeenRendered: React.MutableRefObject<boolean>,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
) => {
  const rewriteSearchString = useRewriteSearchString()

  useEffect(() => {
    rewriteSearchString({ page: currentPage, view: activeTab })
  }, [currentPage])

  useEffect(() => {
    const filterValuesKeys: any = {}
    Object.keys(filterValues).forEach((key) => {
      filterValuesKeys[key] = undefined
    })

    if (hasPageBeenRendered.current) {
      rewriteSearchString({ page: 1, ...filterValuesKeys })
      setCurrentPage(1)
    }

    hasPageBeenRendered.current = true
  }, [activeTab])
}

const resetStateOnFilterChange = (
  filterValues: any,
  prevFilterValues: any,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  setPrevFilterValues: React.Dispatch<any>
) => {
  if (!_.isEqual(filterValues, prevFilterValues)) {
    setCurrentPage(1)
    setPrevFilterValues(filterValues)
  }
}
