import ExcelJS from 'exceljs'

import { getExportConfig as policiesExportConfig } from '../../Policies/getExportConfig'
import { getExportConfig as criteriaExportConfig } from '../../Criteria/getExportConfig'

import { formatData, downloadExcel, styleHeaders } from './utils'

export const SHEET_NAME = 'Payer Policy Tracker'

export const exportExcelSheet = async ({ data, view }) => {
  if (!data.length) {
    return
  }

  const exportConfig =
    view === 'policies' ? policiesExportConfig() : criteriaExportConfig()
  const formattedData = formatData({ data, exportConfig, view })
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(SHEET_NAME)

  worksheet.columns = exportConfig.map(({ Header, accessor }) => ({
    header: Header,
    key: accessor,
    width: 30,
  }))
  formattedData.forEach((row) => worksheet.addRow(row))
  styleHeaders(worksheet)
  downloadExcel(workbook)
}
