export const styleHeaders = (worksheet: any) => {
  const row = worksheet.getRow(1)
  row.font = {
    name: 'Inter',
    size: 11,
    bold: true,
    color: { argb: 'FFFFFF' },
  }
  row.alignment = { horizontal: 'center' }
  row.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '0070C0' },
  }
  row.height = 30
  row.alignment = { vertical: 'middle', horizontal: 'center' }
  row.border = {
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } },
  }
}
