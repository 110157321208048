import { useMutation, useQuery } from '@apollo/client'

import * as CRUD_UTILS from 'frontend/api/utils'
import { CrudHookOptions } from 'frontend/interfaces/CrudHookOptions'
import * as GQL_TAGS from './gql-tags'
import { useQueryString } from 'frontend/hooks'

interface Options extends CrudHookOptions {
  isExport?: boolean
}

export const PAGE_SIZE = 50

export const usePayerPolicyDocument = ({
  queryInput = {},
  isExport = false,
}: Options = {}) => {
  let {
    page,
    view,
    payer_policy_document_id,
    ...filterValues
  }: any = useQueryString()
  if (page && !isExport) {
    queryInput.page = Number(page)
    queryInput.page_size = PAGE_SIZE
  }
  if (payer_policy_document_id) {
    queryInput.id = payer_policy_document_id
  }

  const { data: payerPolicyDocuments, loading: isLoading } = CRUD_UTILS.useRead(
    {
      tag: GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS,
      input: { ...queryInput, filterValues },
    }
  )

  const {
    data: payerPolicyProducts,
    loading: isCriteriaLoading,
  } = CRUD_UTILS.useRead({
    tag: GQL_TAGS.GET_PAYER_POLICY_PRODUCTS,
    input: { ...queryInput, filterValues },
  })

  const { data: countResponse, loading: isLoadingCount } = CRUD_UTILS.useRead({
    tag: GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS_COUNT,
    input: filterValues,
    options: { networkPolicy: 'network-only' },
  })

  const { data: filterOptions, loading: isLoadingFilterOptions } = useQuery(
    GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS_FILTER_OPTIONS
  )

  const [create, { loading: isCreating, data: createData }] = useMutation(
    GQL_TAGS.CREATE_PAYER_POLICY_DOCUMENT
  )

  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate({
    tag: GQL_TAGS.UPDATE_PAYER_POLICY_DOCUMENT,
  })

  const data = payerPolicyDocuments
    ? Object.values(payerPolicyDocuments)[0]
    : []

  const criteria = payerPolicyProducts
    ? Object.values(payerPolicyProducts)[0]
    : []

  return {
    data,
    criteria,
    isCriteriaLoading,
    count: countResponse?.payerPolicyDocumentsCount,
    pageSize: PAGE_SIZE,
    filterOptions,
    isLoadingCount,
    isLoadingFilterOptions,
    isLoading,
    create,
    isCreating,
    createData,
    update,
    isUpdating,
  }
}
