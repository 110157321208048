import _ from 'lodash'

import { useQueryString } from 'frontend/hooks'
import { useClient } from 'frontend/entity-hooks/ember'

export const useModalClient = (setForm: any) => {
  const { client: selectedClient } = useQueryString()
  const {
    isLoading: isClientLoading,
    data: selectedClientData,
  }: any = useClient({
    queryInput: { id: selectedClient },
  })

  const { isLoading: areClientsLoading, data: clients }: any = useClient()

  let options = []
  if (!areClientsLoading && !isClientLoading) {
    options = clients
      .map((client: any) => ({
        label: client.name,
        value: client.id,
      }))
      .filter((client: any) => client.value !== selectedClient)
  }

  const onChange = (option: any) => {
    setForm((prev: any) => ({
      ...prev,
      client: option,
      teams: [],
      primaryTeam: null,
    }))
  }

  return {
    options,
    onChange,
    isLoading: isClientLoading || areClientsLoading,
    data: selectedClientData && selectedClientData[0],
  }
}
