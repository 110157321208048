import React, { useState } from 'react'
import _ from 'lodash'

import Button from 'frontend/components/Button'
import MoveModal, { useModalUser } from './MoveModal'

const MoveButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  const cancel = () => {
    setIsOpen(false)
  }

  const { data: usersToMove, isLoading } = useModalUser()

  if (isLoading || _.isEmpty(usersToMove)) return null

  return (
    <>
      <Button
        type="secondary"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        Move
      </Button>
      <MoveModal isOpen={isOpen} cancel={cancel} usersToMove={usersToMove} />
    </>
  )
}

export default MoveButton
