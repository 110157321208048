import React, { useState } from 'react'
import { IconButton } from '@mui/material'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  documentProduct: any
  policyState: any
  setPolicyState: any
}

export const FlaggedButton = ({
  documentProduct,
  policyState,
  setPolicyState,
}: Props) => {
  const [value, setValue] = useState(policyState.products[documentProduct.id]?.has_language_changes)

  const toggleFlag = () => {
    const newValue = !value

    setPolicyState(({ products, ...policyState }: any) => {
      if (products[documentProduct.id]) {
        products[documentProduct.id].has_language_changes = newValue
      } else {
        products[documentProduct.id] = { has_language_changes: newValue }
      }
      setValue(newValue)

      return {
        ...policyState,
        products,
      }
    })
  }

  return (
    <IconButton
      color="error"
      disableRipple
      onClick={toggleFlag}
      sx={{
        width: '13px',
        height: '14px',
      }}
    >
      <CustomSvgIcon
        styles={{ width: 14, height: 14 }}
        iconName={value ? 'FlagFilled' : 'Flag'}
        iconColor={value ? '#FF8B33' : '#BBBFC3'}
      />
    </IconButton>
  )
}
