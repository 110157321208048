import React, { useContext, useEffect } from 'react'

import { Input } from 'frontend/components'
import { UtilityBarContext } from '../Panel'
import { useFlags } from 'launchdarkly-react-client-sdk'

const BulkActionCheckbox = ({ data }: { data: { id: string } }) => {
  const utilityBar = useContext(UtilityBarContext)
  const isChecked = utilityBar.checked.has(data.id)
  useEffect(() => {}, [utilityBar.checked])

  const FLAG = useFlags()
  
  if (!FLAG.bulkMoveUserToClient) return null

  const handleInputChange = () => {
    if (isChecked) {
      utilityBar.checked.delete(data.id)
    } else {
      utilityBar.checked.add(data.id)
    }
  }

  return (
    <Input
      value={isChecked}
      onChange={handleInputChange}
      style={{
        flex: '0 0 12px',
        marginLeft: 8,
        cursor: 'pointer',
        filter: isChecked ? null : 'opacity(.5)',
      }}
      type="checkbox"
      name="bulk-user-action"
    />
  )
}

export default BulkActionCheckbox
