import { useContext } from 'react'
import _ from 'lodash'

import { useEmberUser } from 'frontend/entity-hooks/ember'
import { useQueryInput } from '../../../../useQueryInput'

import { UtilityBarContext } from '../../../Panel'

export const useModalUser = () => {
  const utilityBarContext = useContext(UtilityBarContext)
  const userQueryInput = useQueryInput()

  const {
    isLoading: areUsersLoading,
    data: users = [] as any[],
  } = useEmberUser({
    queryInput: userQueryInput,
  })

  const usersToMove = []
  if (!areUsersLoading && users) {
    for (const user of users as any[]) {
      if (utilityBarContext.checked.has(user.id)) {
        usersToMove.push(user)
      }
    }
  }

  return {
    data: usersToMove,
    isLoading: areUsersLoading,
  }
}
