import React from 'react'
import _ from 'lodash'

import { DataTable } from 'frontend/components'
import getColConfig from './getColConfig'

const LABEL_STYLES = {
  color: '#0E2539',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
}

const Label = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <label style={LABEL_STYLES}>Product(s) Search Term</label>
    {/* <Button type="secondary" buttonStyle={BUTTON_STYLE} onClick={() => {}}>
        <CirclePlus />
        <div style={{ marginTop: 2 }}>Add product</div>
      </Button> */}
  </div>
)

interface Props {
  products: any
  isLoading: boolean
  setPolicyState: any
}

const ProductTable = ({
  products,
  isLoading,
  setPolicyState,
}: Props) => {
  const data = (products || []).map(
    ({
      id: instructionProductId,
      product,
      search_term,
    }: {
      id: string
      product: { brand_name: string }
      search_term: string
    }) => ({
      id: instructionProductId,
      product: product?.brand_name,
      search_term: search_term,
    })
  )

  const updateFn = (product: any) => {
    setPolicyState((prevState: any) => {
      const instruction_products = prevState?.instruction_products || {}
      instruction_products

      return {
        ...prevState,
        instruction_products: { ...instruction_products, [product.id]: { search_term: product.search_term } },
      }
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px 0px',
        paddingBottom: 24,
      }}
    >
      <Label />
      <DataTable
        isLoading={isLoading || !products}
        hasReset={false}
        hasExport={false}
        hasRowCount={false}
        isEditable={true}
        isPaginated={false}
        colConfig={getColConfig(updateFn)}
        data={data}
      />
    </div>
  )
}

export default ProductTable
