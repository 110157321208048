import React from 'react'
import _ from 'lodash'

import { Select } from 'frontend/components'
import FieldLabel from '../FieldLabel'

const TRACKING_OPTIONS = [
  { value: 'automated', label: 'Automated' },
  { value: 'manual', label: 'Manual' },
]

interface Props {
  instruction: any
  policyState: any
  setPolicyState: any
}

const TrackingMethod = ({ instruction, policyState, setPolicyState }: Props) => {
  const value = {
    value: policyState?.tracking_method || instruction?.tracking_method,
    label: _.capitalize(policyState?.tracking_method || instruction?.tracking_method),
  }

  const handleOnChange = (pickedOption: { label: string; value: string }) =>
    setPolicyState((prevState: any) => ({
      ...prevState,
      tracking_method: pickedOption.value,
    }))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px 0px' }}>
      <FieldLabel>Tracking Method</FieldLabel>
      <Select
        options={TRACKING_OPTIONS}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default TrackingMethod
