import React from 'react'

const LeftArrow = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.86956 4.36752L2.21316 0.259349C1.82121 -0.0864497 1.18574 -0.0864497 0.793796 0.259349C0.401851 0.605147 0.401851 1.1658 0.793796 1.5116L4.74773 5L0.793796 8.4884C0.401851 8.8342 0.401851 9.39485 0.793796 9.74065C1.18574 10.0864 1.82121 10.0864 2.21316 9.74065L6.86956 5.63248C7.26549 5.28317 7.26549 4.71683 6.86956 4.36752Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default LeftArrow
