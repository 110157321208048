import React, { createContext, useState } from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import UsersListPanel from './UsersListPanelOuterContainer'
import UsersPanelHeader from './UsersPanelHeader'

const Wrapper = styled.div({
  backgroundColor: Colors.WHITE,
  borderRadius: 8,
  marginBottom: 24,
  paddingBottom: 12,
})

import { Dispatch, SetStateAction } from 'react'
import UtilityBar from './UtilityBar'

export const UtilityBarContext = createContext<{data: any[], setData: Dispatch<SetStateAction<any[]>>, checked: Set<unknown>, setChecked: Dispatch<SetStateAction<Set<unknown>>> }>({ data: [], setData: () => {}, checked: new Set(), setChecked: () => {} })

const Panel = () => {
  const [checked, setChecked] = useState(new Set())
  const [data, setData] = useState<any[]>([])
  return (
    <UtilityBarContext.Provider value={{ checked, setChecked, data, setData }}>
      <Wrapper>
        <UsersPanelHeader />
        <UtilityBar />
        <UsersListPanel />
      </Wrapper>
    </UtilityBarContext.Provider>
  )
}

export default Panel
