import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import _ from 'lodash'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import {
  CLEAR_ALL,
  SELECT_ALL,
  CLEAR_ALL_OPTION,
  SELECT_ALL_OPTION,
  styles,
} from './constants'
import { getOptions } from './getOptions'
import { Option } from './Option'
import { ValueContainer } from './ValueContainer'

interface Props {
  column: any
  enumAccessors: Set<string>
  isDateColumn?: boolean
}

export const MultiSelectFilter = ({
  column,
  enumAccessors,
  isDateColumn,
}: Props) => {
  const rewriteSearchString = useRewriteSearchString()
  const { filterOptions, isLoadingFilterOptions } = usePayerPolicyDocument()
  const { id: accessor, labelMap }: { id: string; labelMap: any } = column
  const { [accessor]: defaultSelectedOptions }: any = useQueryString()
  const options = getOptions({
    accessor,
    labelMap,
    filterOptions,
    isLoadingFilterOptions,
    isDateColumn,
  })
  const allOptions = [CLEAR_ALL_OPTION, SELECT_ALL_OPTION, ...options]

  const [selectedOptions, setSelectedOptions] = useState(undefined)

  useEffect(() => {
    if (!isLoadingFilterOptions) {
      setSelectedOptions(
        getDefaultOption(
          defaultSelectedOptions,
          allOptions,
          accessor,
          enumAccessors
        )
      )
    }
  }, [isLoadingFilterOptions])

  const setNewOptions = ({ queryStringOptions, stateOptions }: any) => {
    rewriteSearchString({ [accessor]: queryStringOptions })
    setSelectedOptions(stateOptions)
  }

  const onChange = (newSelectedOptions: any) => {
    // handle clear all or none selected
    if (
      newSelectedOptions === null ||
      newSelectedOptions.length === 0 ||
      _.some(newSelectedOptions, { value: CLEAR_ALL })
    ) {
      setNewOptions({ queryStringOptions: undefined, stateOptions: [] })

      return
    } else {
      // handle select all
      const hasAllSelected = _.some(newSelectedOptions, { value: SELECT_ALL })
      let newOptions = newSelectedOptions
      if (hasAllSelected) {
        newOptions = options
      }

      const formattedOptions = newOptions.map((option: any) => {
        if (enumAccessors.has(accessor)) return option.value

        return option.label
      })

      setNewOptions({
        queryStringOptions: formattedOptions,
        stateOptions: newSelectedOptions,
      })
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ReactSelect
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuPosition={'absolute'}
        onChange={onChange}
        options={allOptions}
        value={selectedOptions}
        components={{ Option, ValueContainer }}
        menuPortalTarget={document.body}
        styles={styles}
      />
    </div>
  )
}

const getDefaultOption: any = (
  selectedOptions: string,
  allOptions: any,
  accessor: string,
  enumAccessors: Set<string>
) => {
  if (selectedOptions === undefined || allOptions.length === 2) return []

  const options = Array.isArray(selectedOptions)
    ? selectedOptions
    : [selectedOptions]

  return options.map((selectedOption: string) => {
    return allOptions.find(({ label, value }: any) => {
      const formattedOption = formatOption(selectedOption)
      if (enumAccessors.has(accessor)) return value === formattedOption

      return label === formattedOption
    })
  })
}

const formatOption = (str: string) => {
  if (str === 'true') return true
  if (str === 'false') return false

  return str
}
