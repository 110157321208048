import DocumentCompare from './DocumentCompare'
import DownCaret from './DownCaret'
import SingleDocument from './SingleDocument'
import Caution from './Caution'
import TriangleCaution from './TriangleCaution'
import ChevronRight from './ChevronRight'
import ChevronLeft from './ChevronLeft'
import ChevronDown from './ChevronDown'
import Calendar from './Calendar'
import Link from './Link'
import Filter from './Filter'
import Info from './Info'
import Error from './Error'
import CircleCheck from './CircleCheck'
import CirclePlus from './CirclePlus'
import CircleX from './CircleX'
import FlagFilled from './FlagFilled'
import Flag from './Flag'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import CircleArrow from './CircleArrow'

const iconPaths = {
  DocumentCompare,
  DownCaret,
  SingleDocument,
  Caution,
  TriangleCaution,
  ChevronRight,
  ChevronLeft,
  Calendar,
  ChevronDown,
  Link,
  Filter,
  Info,
  Error,
  CircleCheck,
  CirclePlus,
  CircleX,
  FlagFilled,
  Flag,
  LeftArrow,
  RightArrow,
  CircleArrow,
}

export default iconPaths
