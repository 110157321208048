import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'
import CirclePlus from 'frontend/components/Icon/Svgs/CirclePlus'

const BUTTON_LABEL = 'Create'
const BUTTON_KEY = 'client-management-create-button'

const ButtonStyle = {
  padding: '2px 8px',
}

const ButtonLabel = styled.div({
  marginRight: 4,
  marginTop: 2,
})

export const IconButton = ({ onClick, ...props }) => {
  return (
    <Button onClick={onClick} style={ButtonStyle} key={BUTTON_KEY} {...props}>
      <CirclePlus />
      <ButtonLabel>{BUTTON_LABEL}</ButtonLabel>
    </Button>
  )
}
