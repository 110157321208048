import React, { useState } from 'react'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import Item from './Item'
import { Wrapper } from './Wrapper'
import BulkActionCheckbox from './BulkActionCheckbox'

const ACTIVE_ITEM_STYLE = {
  background: transparentize(0.9, Colors.BLUE),
  fontWeight: 600,
}

export const ListItem = ({
  data,
  isSelected,
}: {
  data: any
  isSelected: boolean
}) => {

  const [isHoveredListItem, setIsHoveredListItem] = useState(false)
  const rewriteSearchString = useRewriteSearchString()

  const rewriteClientParam = () => {
    if (!isSelected) {
      rewriteSearchString({
        user: data.id,
      })
    }
  }
  const wrapperStyle = isSelected ? ACTIVE_ITEM_STYLE : {}

  return (
    <Wrapper
      onClick={rewriteClientParam}
      onMouseEnter={() => setIsHoveredListItem(true)}
      onMouseLeave={() => setIsHoveredListItem(false)}
      style={wrapperStyle}
    >
      <BulkActionCheckbox data={data} />
      <Item
        isHoveredListItem={isHoveredListItem}
        isSelected={isSelected}
        data={data}
      />
    </Wrapper>
  )
}
