import { useTeam } from 'frontend/entity-hooks/ember'

export const useModalTeam = (client: string | undefined, setForm: any) => {
  const { isLoading, data }: any = useTeam({
    queryInput: { client },
  })

  const options = (data || []).map((team: any) => ({
    label: team.name,
    value: team.id,
  }))

  const onChange = (options: any) => {
    setForm((prev: any) => ({
      ...prev,
      teams: options || [],
    }))
  }

  return {
    data,
    isLoading,
    options,
    onChange,
  }
}
