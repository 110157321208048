import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { ClearFiltersButton } from './ClearFiltersButton'
import { ExportButton } from './ExportButton'
import { RowCount } from './RowCount'
import { ViewTabs } from './ViewTabs'
interface Props {
  rowCount: number
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Wrapper = styled.div({
  display: 'flex',
  padding: '10px 10px 12px 12px',
  width: '100%',
})

const UtilityBar = ({ rowCount, activeTab, setActiveTab }: Props) => {
  return (
    <Wrapper>
      <ViewTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <RowCount rowCount={rowCount} />
      <ClearFiltersButton />
      <ExportButton />
    </Wrapper>
  )
}

export default UtilityBar
