import React from 'react'

import SelectAllCheckbox from './SelectAllCheckbox'
import MoveButton from './MoveButton'
import { useFlags } from 'launchdarkly-react-client-sdk'

const UtilityBar = () => {
  const FLAG = useFlags()
  if (!FLAG.bulkMoveUserToClient) return null

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px',
        gap: 12,
        height: 30,
      }}
    >
      <SelectAllCheckbox />
      <MoveButton />
    </div>
  )
}

export default UtilityBar
