import React from 'react'

import { ScrollableTextInput } from 'frontend/components'
import FieldLabel from '../FieldLabel'

interface Props {
  instruction: any
  policyState: any
  setPolicyState: any
}

const NavigationInstructions = ({
  instruction,
  policyState,
  setPolicyState,
}: Props) => {
  const handleOnChange = (e: any) => {
    setPolicyState((prevState: any) => ({
      ...prevState,
      navigation_instructions: e.currentTarget.value,
    }))
  }
  const text =
    policyState?.navigation_instructions ?? instruction?.navigation_instructions
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px 0px' }}>
      <FieldLabel>Navigation Instructions</FieldLabel>
      <ScrollableTextInput text={text}  onChange={handleOnChange}/>
    </div>
  )
}

export default NavigationInstructions
