import React from 'react'
import _ from 'lodash'


import { Username } from '../../ListItem/Item/ContentWrapper/Username'
import { useModalUser } from './hooks'

const UserList = () => {
  const { data } = useModalUser()

  return (
    <div style={{ margin: 12, marginTop: 0 }}>
      <ul
        style={{
          background: 'white',
          margin: ' 0px 12px',
          padding: '0px 24px',
          paddingBottom: 24,
        }}
      >
        {data.map((user: any) => (
          <li key={user.id} style={{ paddingLeft: 6 }}>
            <Username key={user.id}>{user.username}</Username>
          </li>
        ))}
      </ul>
    </div>

  )
}

export default UserList
