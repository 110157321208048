import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import { useRewriteSearchString, useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

const ICON_STYLES = {
  width: '15px',
  height: '14px',
  marginRight: '5px',
}

const BUTTON_STYLES = {
  backgroundColor: Colors.COOL_GRAY_100,
  whiteSpace: 'nowrap',
  padding: 10,
  height: 34,
  width: 110,
}

const ButtonLabel = styled.div({
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  color: '#607282',
})

export const ClearFiltersButton = () => {
  const rewriteSearchString = useRewriteSearchString()
  const { page, view, ...filterValues } = useQueryString()
  const onClick = () => {
    const filterValuesKeys = {}
    Object.keys(filterValues).forEach((key) => {
      filterValuesKeys[key] = undefined
    })

    rewriteSearchString({
      ...filterValuesKeys,
    })
  }

  return (
    <div>
      <Button onClick={onClick} style={BUTTON_STYLES}>
        <CustomSvgIcon
          iconName="CircleArrow"
          iconColor={'#607282'}
          styles={ICON_STYLES}
        />
        <ButtonLabel>Clear Filters</ButtonLabel>
      </Button>
    </div>
  )
}
