import React, { createContext, useEffect, useState } from 'react'
import _ from 'lodash'

import SingleActionDialog from 'frontend/components/Dialog/SingleActionDialog'

import UserList from './UserList'
import { useModalClient } from './hooks'
import { Description } from './Description'
import Form from './Form'

interface Props {
  cancel: () => void
  isOpen: boolean
  usersToMove: any[]
}

type FormState = {
  client: { value: string, label: string } | undefined,
  teams: { value: string, label: string }[],
  users: any[],
  primaryTeam: { value: string, label: string } | undefined,
};

export const FormContext = createContext<{
  form: FormState,
  setForm: React.Dispatch<React.SetStateAction<FormState>>
}>({
  form: {
    client: undefined,
    teams: [],
    users: [],
    primaryTeam: undefined,
  },
  setForm: () => {},
})

const MoveModal = ({ isOpen, cancel, usersToMove }: Props) => {
  const [form, setForm] = useState<{
    client: { value: string, label: string } | undefined,
    teams: { value: string, label: string }[],
    users: any[],
    primaryTeam: { value: string, label: string } | undefined,
  }>({
    client: undefined,
    teams: [],
    users: usersToMove,
    primaryTeam: undefined,
  })

  const { data: client } = useModalClient(setForm)

  useEffect(() => {
    const isPrimaryTeamValid = form.teams.find(({ value }) => value === form.primaryTeam?.value)
    if (form.teams && !isPrimaryTeamValid) {
      setForm((prev: any) => ({ ...prev, primaryTeam: form.teams[0] || null }))
    }
  }, [form.teams])

  if (!isOpen) return null

  return (
    <SingleActionDialog
      header={`Move ${client?.data?.name} Users`}
      backgroundColor="white"
      submitText={'Move'}
      subheader="Remember to push permissions after submitting."
      submitHandler={() => {}}
      cancelHandler={cancel}
    >
      <Description />
      <div style={{ padding: 12, margin: 12, marginTop: 0 }}>
        <UserList />
        <FormContext.Provider value={{ form, setForm }}>
          <Form />
        </FormContext.Provider>
      </div>
    </SingleActionDialog>
  )
}

export default MoveModal
