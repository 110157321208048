import React from 'react'

const CirclePlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 20 20"
      style={{ margin: 6 }}
    >
      <path
        className="color1"
        fill="#FFFFFF"
        fill-rule="evenodd"
        d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,1.81818182 C5.48130659,1.81818182 1.81818182,5.48130659 1.81818182,10 C1.81818182,14.5186934 5.48130659,18.1818182 10,18.1818182 C14.5186934,18.1818182 18.1818182,14.5186934 18.1818182,10 C18.1818182,5.48130659 14.5186934,1.81818182 10,1.81818182 Z M10,6 C10.5522847,6 11,6.45303631 11,6.99703014 L10.999,9 L13.0029699,9 C13.5142826,9 13.9356987,9.38272018 13.9932922,9.88266744 L14,10 C14,10.5522847 13.5469637,11 13.0029699,11 L10.999,11 L11,13.0029699 C11,13.5142826 10.6172798,13.9356987 10.1173326,13.9932922 L10,14 C9.44771525,14 9,13.5469637 9,13.0029699 L8.999,11 L6.99703014,11 C6.4463856,11 6,10.5561352 6,10 C6,9.44771525 6.45303631,9 6.99703014,9 L8.999,9 L9,6.99703014 C9,6.4463856 9.44386482,6 10,6 Z"
      />
    </svg>
  )
}

export default CirclePlus
