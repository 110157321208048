import { formatDateMonthYearDash } from 'frontend/utils/formatDate'
import { getPolicyStatus } from './getPolicyStatus'

export const formatColumnData = (data: any[]) => {
  return data.map(
    ({
      payer_organization,
      book_of_business,
      instruction,
      products,
      updated_at,
      ...rest
    }) => {
      const {
        productNames,
        filteredProducts,
        productPolicyStatuses,
      } = products.reduce(
        (acc: any, product: any) => {
          if (!product.product) return acc

          const brand_name = product.product?.brand_name || ''
          acc.filteredProducts.push(product)
          acc.productNames.push(brand_name)
          acc.productPolicyStatuses.push(product.policy_status)
          return acc
        },
        {
          productNames: [],
          filteredProducts: [],
          productPolicyStatuses: [],
        }
      )

      return {
        payer_organization_name: payer_organization.name,
        book_of_business_name: book_of_business.name,
        instruction_status: instruction.instruction_status,
        payer_website_link: instruction.payer_website_link,
        products: filteredProducts,
        product_names: productNames,
        assignees: getAssignees(instruction),
        updated_at: formatDateMonthYearDash(updated_at),
        project: getInstructionProjectNames(productNames, instruction),
        product_policy_status: getPolicyStatus(productPolicyStatuses).policyStatus,
        ...rest,
      }
    }
  )
}

const getInstructionProjectNames = (productNames: any, instruction: any) => {
  const docProductNamesSet = new Set(productNames)
  const sharedInstructionProducts = (instruction.products || [])
    .filter((instructionProduct: { product: { brand_name: any} } ) => {
      return docProductNamesSet.has(instructionProduct?.product?.brand_name)
    })

  const allProjectNames = sharedInstructionProducts.map(
    (instructionProduct: { payer_policy_project: { name: any } }) =>
      instructionProduct?.payer_policy_project?.name
    )

  const uniqueProjectNames = Array.from(new Set(allProjectNames))

  return uniqueProjectNames
}

const getAssignees = (instruction: any) => {
  return instruction &&
    instruction.products &&
    instruction?.products[0]?.assigned_criteria_reviewers
}

