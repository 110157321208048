import React from 'react'
import styled from '@emotion/styled'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  currentPage: number
  setCurrentPage: any
}

const Wrapper = styled.span({
  marginRight: 4,
})

const Text = styled.span({
  fontSize: 12,
})

export const LeftButton = ({ currentPage, setCurrentPage }: Props) => {
  const disabled = currentPage === 1
  const buttonColor = disabled ? '#9DAEC4' : '#798BA3'

  return (
    <Wrapper style={{ color: buttonColor }}>
      <button
        disabled={disabled}
        onClick={() => {
          setCurrentPage(currentPage - 1)
        }}
      >
        <CustomSvgIcon
          iconName="LeftArrow"
          iconColor={buttonColor}
          styles={{ width: 10, height: 10, marginRight: 0.5 }}
        />
        <Text>Prev</Text>
      </button>
    </Wrapper>
  )
}
