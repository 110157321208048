import React from 'react'
import { transparentize } from 'polished'

import { DataTable } from 'frontend/components'
import { AlphaColors, Colors } from 'frontend/utils/pulseStyles'

// TODO: Only load skeleton on initial load
// import LoadingSkeleton from '../LoadingSkeleton'

const EXCEL_LABEL = 'Payer Policy Tracker'
const TABLE_ID = 'payer-policy-tracker-v1'

const TABLE_STYLE_CONFIG = {
  superheaders: {
    display: 'flex',
    justifyContent: 'center',
    background: transparentize(0.9, Colors.PRIMARY),
    color: Colors.PRIMARY,
    fontSize: 11,
    letterSpacing: -0.2,
    fontWeight: 600,
    lineHeight: '16px',
    padding: 0,
    borderBottom: 'none',
    borderRight: `1px solid ${AlphaColors.Black10}`,
  },
}

const superHeaderStyle = {
  '.table .header > div:first-child': {
    background: Colors.BLUE_100,
  },
}

interface Props {
  data: any[]
  isLoading: boolean
  colConfig: any
  exportConfig: any
  filterAccessorsToShow: string[]
}

const Policies = ({
  data,
  isLoading,
  colConfig,
  exportConfig,
  filterAccessorsToShow,
}: Props) => {

  return (
    <DataTable
      tableId={TABLE_ID}
      data={data}
      isLoading={isLoading}
      isFocusable
      isPaginated={false}
      hasReset={false}
      hasExport={false}
      hasRowCount={false}
      exportConfig={exportConfig}
      excelLabel={EXCEL_LABEL}
      isHoverable
      colConfig={colConfig}
      defaultColWidths={{ minWidth: 50, width: 200 }}
      queryStringConfig={{
        showSearchFilter: true,
        filterAccessorsToShow,
      }}
      styleConfig={TABLE_STYLE_CONFIG}
      wrapperStyle={superHeaderStyle}
    />
  )
}

export default Policies
