import { formatColumnData as formatPoliciesData } from '../../../Policies/column-config/formatColumnData'
import { formatColumnData as formatCriteriaData } from '../../../Criteria/column-config/formatColumnData'
import { getConfigFormattedFields } from './getConfigFormattedFields'

export const formatData = ({ data, exportConfig, view }: any) => {
  const rowData =
    view === 'policies' ? formatPoliciesData(data) : formatCriteriaData(data)
  const formattedData: any[] = []

  rowData.forEach((row: any) => {
    const configFormattedFields = getConfigFormattedFields(row, exportConfig)
    const formattedDatum = { ...row, ...configFormattedFields }

    formattedData.push(formattedDatum)
  })

  return formattedData
}
