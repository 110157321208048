import React, { useContext, useEffect, useState } from 'react'

import { Input } from 'frontend/components'
import { UtilityBarContext } from '../Panel'
import { useQueryString } from 'frontend/hooks'

const SelectAllCheckboxContainer = () => {
  const { team } = useQueryString()
  const utilityBarContext = useContext(UtilityBarContext)

  const hasAllChecked =
    utilityBarContext.checked.size > 0 &&
    utilityBarContext.checked.size === utilityBarContext.data.length

  const [checked, setChecked] = useState(hasAllChecked)
  const handleOnClick = () => {
    const isChecked = !checked
    if (isChecked) {
      const ids = utilityBarContext.data.map((item) => item.id)
      utilityBarContext.setChecked(new Set(ids))
      setChecked(true)
    } else if (!isChecked) {
      utilityBarContext.setChecked(new Set())
      setChecked(false)
    }
  }

  useEffect(() => {
    setChecked(false)
  }, [team])

  return (
    <SelectAllCheckbox handleOnClick={handleOnClick} checked={checked} />
  )
}

interface Props {
  handleOnClick: () => void
  checked: boolean
}

const SelectAllCheckbox = ({
  handleOnClick,
  checked,
}: Props) => {
  return (
    <div style={{ height: 12, display: 'flex', alignItems: 'center', paddingLeft: 6, gap: 8 }}>
      <Input
        style={{ cursor: 'pointer' }}
        onChange={handleOnClick}
        name="user-panel-check-all"
        type="checkbox"
        value={checked}
      />
    </div>
  )
}

export default SelectAllCheckboxContainer
