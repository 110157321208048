import React from 'react'

const CircleArrow = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8129 3.28471C10.3998 0.751373 6.52714 0.692013 4.04388 3.06377L3.46841 2.45962C3.2437 2.22371 2.90719 2.13569 2.59863 2.22371C2.29007 2.3267 2.05167 2.59195 1.99521 2.91588L1.35013 6.06831C1.29423 6.37787 1.37808 6.68683 1.58854 6.90778C1.79899 7.12872 2.09386 7.21733 2.38816 7.15806L5.3904 6.49523C5.55866 6.45093 5.71321 6.36289 5.82557 6.24495C5.92367 6.14195 6.00808 6.00904 6.05028 5.86174C6.13468 5.53781 6.05028 5.18394 5.82557 4.94863L5.27861 4.37441C7.08826 2.69553 9.83851 2.76918 11.5638 4.58096C13.3599 6.46649 13.3599 9.53039 11.5638 11.4152C9.76836 13.3007 6.84988 13.3007 5.05398 11.4152C4.4785 10.811 4.07185 10.0602 3.86139 9.23506C3.73533 8.74887 3.25797 8.45428 2.79485 8.5872C2.33173 8.71953 2.05112 9.22069 2.17773 9.70688C2.45834 10.8409 3.03382 11.887 3.83347 12.7114C6.31672 15.3184 10.3433 15.3184 12.8273 12.7114C15.2821 10.1187 15.2821 5.87719 12.813 3.28449L12.8129 3.28471Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default CircleArrow
