import { defaultStyles } from 'frontend/components/Select/Select'
import { Colors } from 'frontend/utils/pulseStyles'

export const SELECT_STYLE = {
  ...defaultStyles,
  control: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.COOL_GRAY_100,
    border: 'none',
    marginBottom: 12,
  }),
}
