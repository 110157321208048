import { Colors, AlphaColors } from 'frontend/utils/pulseStyles'

export const CLEAR_ALL = 'Clear All'
export const SELECT_ALL = 'Select All'

export const CLEAR_ALL_OPTION = {
  label: CLEAR_ALL,
  value: CLEAR_ALL,
}

export const SELECT_ALL_OPTION = {
  label: SELECT_ALL,
  value: SELECT_ALL,
}

export const SHARED_TYPOGRAPHY_STYLE = {
  fontSize: 11,
  fontWeight: 500,
}

export const styles = {
  option: (provided: any, { data: { value }, isSelected, isFocused }: any) => {
    const isAllOption = value === CLEAR_ALL || value === SELECT_ALL
    let color = isAllOption ? AlphaColors.Black50 : Colors.BLACK
    if (isSelected) color = Colors.PRIMARY

    return {
      ...provided,
      fontSize: 13,
      padding: 0,
      backgroundColor: isFocused ? Colors.COOL_GRAY_100 : 'transparent',
      color,
      fontWeight: isAllOption ? 700 : 400,
      ':active': {
        backgroundColor: 'transparent',
      },
    }
  },
  control: (provided: any) => ({
    ...provided,
    ...SHARED_TYPOGRAPHY_STYLE,
    ':hover': { borderColor: 'transparent' },
    ':focus': { outline: 'none' },
    boxShadow: 'none',
    borderColor: 'none',
    minHeight: 28,
    maxHeight: 28,
    backgroundColor: Colors.COOL_GRAY_100,
    flexWrap: 'no-wrap',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided: any) => ({
    ...provided,
    ...SHARED_TYPOGRAPHY_STYLE,
    color: 'transparent',
    textAlign: 'left',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: AlphaColors.Black50,
    height: 24,
    padding: '2px 6px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    color: AlphaColors.Black50,
    margin: '6px 0px 6px 6px',
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.25)',
    position: 'relative',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.WHITE,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    ...SHARED_TYPOGRAPHY_STYLE,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: AlphaColors.Black50,
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    ...SHARED_TYPOGRAPHY_STYLE,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...SHARED_TYPOGRAPHY_STYLE,
    color: AlphaColors.Black50,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: AlphaColors.Black50,
    flexWrap: 'nowrap',
  }),
}
