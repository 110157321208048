import React, { useContext } from 'react'

import { Select } from 'frontend/components'
import { Label, SELECT_STYLE } from '../shared'
import { useModalTeam } from '../hooks'
import { FormContext } from '../MoveModal'

const TeamsSelect = () => {
  const { form, setForm } = useContext(FormContext)
  const team = useModalTeam(form?.client?.value, setForm)

  return (
    <>
      <Label>On the following teams</Label>
      <Select
        isMulti
        styles={SELECT_STYLE}
        value={form?.teams}
        options={team.options}
        placeholder={'pick new teams'}
        onChange={team.onChange}
      />
    </>
  )
}

export default TeamsSelect
