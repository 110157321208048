import { getPolicyDetailsColumns } from './getPolicyDetailsColumns'
import { getLinkAutomationDetailsColumns } from './getLinkAutomationDetailsColumns'

const ENUM_ACCESSORS = new Set([
  'has_language_changes',
  'benefit_type',
  'product_policy_status',
  'instruction_status',
])

export const getColumnConfig = ({ setModalType }) => {
  return [
    {
      Header: 'Policy Details',
      id: 'Policy Details',
      columns: getPolicyDetailsColumns({
        setModalType,
        enumAccessors: ENUM_ACCESSORS,
      }),
    },
    {
      Header: 'Link/Automation Details',
      id: 'Link/Automation Details',
      columns: getLinkAutomationDetailsColumns({
        setModalType,
        enumAccessors: ENUM_ACCESSORS,
      }),
    },
  ]
}
