import { formatDateMonthYearDash } from 'frontend/utils/formatDate'

import { getPolicyStatus } from './getPolicyStatus'

export const formatColumnData = (data: any[]) => {
  return data.map(
    ({
      payer_organization,
      book_of_business,
      instruction,
      products,
      updated_at,
      ...rest
    }) => {
      const { policyStatus } = getPolicyStatus(
        rest.policy_status ? [rest.policy_status] : []
      )

      const formattedProductUpdatedAt = formatDateMonthYearDash(
        rest.product_updated_at
      )

      const instructionProduct = instruction?.products.find(
        ({ product }: { product: { id: any } }) =>
          product.id === rest.product_id
      )

      return {
        ...rest,
        assignees:
          instruction &&
          instruction.products &&
          instructionProduct?.assigned_criteria_reviewers,
        project:
          instruction &&
          instruction.products &&
          instructionProduct?.payer_policy_project?.name,
        payer_organization_name: payer_organization.name,
        payer_organization_slug: payer_organization.slug,
        book_of_business_name: book_of_business.name,
        instruction_status: instruction.instruction_status,
        payer_website_link: instruction.payer_website_link,
        products: [rest],
        product_names: rest.brand_name,
        product_policy_status: policyStatus,
        product_updated_at: formattedProductUpdatedAt,
        has_language_changes: rest.has_language_changes,
      }
    }
  )
}
