import React from 'react'
import { components } from 'react-select'
import styled from '@emotion/styled'

import { AlphaColors, Colors } from 'frontend/utils/pulseStyles'

import { CLEAR_ALL, SELECT_ALL } from './constants'

const Divider = styled.div({
  borderBottom: `1px solid ${AlphaColors.Black10}`,
  margin: '3px 8px',
})

const StyledOption = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 8px',
  borderRadius: 4,
  ':hover': {
    backgroundColor: Colors.COOL_GRAY_100,
  },
})

export const Option = (props) => {
  const { value, isSelected, label } = props

  const isSelectAll = value === SELECT_ALL
  const isClearAll = value === CLEAR_ALL
  const shouldDisplayCheckBox = !(isSelectAll || isClearAll)

  return (
    <>
      <components.Option {...props} key={value}>
        <StyledOption>
          {shouldDisplayCheckBox ? (
            <input
              type="checkbox"
              checked={isSelected}
              style={{ height: 12, marginRight: 6 }}
            />
          ) : null}

          <label>{label}</label>
        </StyledOption>
      </components.Option>
      {isSelectAll ? <Divider /> : null}
    </>
  )
}
