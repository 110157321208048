import React from 'react'

import { useRewriteSearchString, useQueryString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import Criteria from './Criteria'
import { formatColumnData } from './column-config/formatColumnData'
import { getExportConfig } from './getExportConfig'
import { getColumnConfig } from './column-config'

const Container = () => {
  const { view } = useQueryString()
  if (view !== 'criteria') return null

  const {
    criteria: latestPayerPolicyDocuments,
    isCriteriaLoading: isLoading,
  }: any = usePayerPolicyDocument()

  const formattedData = formatColumnData(latestPayerPolicyDocuments)
  const exportConfig = getExportConfig()
  const colConfig = getColumnConfig({ setModalType: useRewriteSearchString() })

  return (
    <Criteria
      data={formattedData}
      isLoading={isLoading}
      exportConfig={exportConfig}
      colConfig={colConfig}
    />
  )
}

export default Container
