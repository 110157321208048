import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { useQueryString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import { Content } from './Content'
import { Header } from './Header'

const Wrapper = styled.div({
  width: 512,
  height: 601,
})

export const PrefilledPolicy = () => {
  const { payer_policy_document_id }: any = useQueryString()
  const {
    isLoading,
    data: payerPolicyDocuments,
  }: any = usePayerPolicyDocument()
  const payerPolicyDocument = payerPolicyDocuments[0]
  const [fieldState, setFieldState] = useState<any>()

  const handleFieldChange = ({ name, value }: any) => {
    const newFieldState = {
      ...fieldState,
      [name]: value,
    }
    setFieldState(newFieldState)
  }

  useEffect(() => {
    if (!isLoading) {
      setFieldState({
        payer_organization: payerPolicyDocument.payer_organization,
        book_of_business: payerPolicyDocument.book_of_business,
        benefit_type: payerPolicyDocument.benefit_type,
        document_type: payerPolicyDocument.document_type,
        products: payerPolicyDocument.products,
        document: payer_policy_document_id,
      })
    }
  }, [isLoading])

  return (
    <Wrapper>
      <Header fieldState={fieldState} />
      <Content onChange={handleFieldChange} fieldState={fieldState} />
    </Wrapper>
  )
}
