import React from 'react'

import { Dialog, Spinner } from 'frontend/components'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'

import PDFViewer from './PDFViewer'

const InnerContainer = () => {
  const { isLoading } = usePayerPolicyDocument()

  if (isLoading)
    return (
      <Dialog
        contentStyle={{
          alignItems: 'center',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
        }}
        contentWrapperStyle={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Dialog>
    )

  return <PDFViewer />
}

export default InnerContainer
