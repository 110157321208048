import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import { getPageNumbers } from './getPageNumbers'

interface Props {
  pageCount: number
  currentPage: number
  setCurrentPage: any
}

const ELLIPSES = '...'

const SELECTED_PAGE_STYLE = {
  backgroundColor: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
}

const Wrapper = styled.span({
  color: '#798BA3',
  margin: '0 2px 0 2px',
  padding: '2px 8px 4px 8px',
  borderRadius: 4,
})

const Number = styled.span({
  fontSize: 12,
  fontWeight: 700,
})

export const PageNumbers = ({
  pageCount = 0,
  currentPage,
  setCurrentPage,
}: Props) => {
  const pageNumbers = getPageNumbers({ pageCount, currentPage })

  return (
    <>
      {pageNumbers.map((pageNum) => {
        const isSelected = currentPage === pageNum
        const wrapperStyle = isSelected ? SELECTED_PAGE_STYLE : {}

        if (pageNum === ELLIPSES) {
          return <Wrapper>{pageNum}</Wrapper>
        }

        return (
          <Wrapper style={wrapperStyle}>
            <button
              onClick={() => {
                setCurrentPage(pageNum)
              }}
            >
              <Number>{pageNum}</Number>
            </button>
          </Wrapper>
        )
      })}
    </>
  )
}
